<template>
  <Page404
    :color="colorPrimary"
  >
    <el-button
      slot="button"
      type="primary"
      size="large"
      @click="handleBackHome"
    >
      返回首页
    </el-button>
  </Page404>
</template>

<script>
import { colorPrimary } from '@/styles/variables.scss'
import ErrorPage from '@indrasoft/error-pages'
import '@indrasoft/error-pages/lib/errorPages.css'

export default {
  name: 'ThePage404',
  components: {
    Page404: ErrorPage.Page404
  },
  data() {
    return {
      colorPrimary
    }
  },
  methods: {
    handleBackHome() {
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>
